import React from 'react'
import { Link } from "gatsby"

const PropertyNavbar = ({ sold }) => {
  return (
    <div className="w-full sticky top-0 z-20 bg-green-600 shadow">      
      <ul className="font-open-sans max-w-6xl mx-auto py-3 md:py-2 flex justify-center items-center text-sm md:text-base space-x-6 md:space-x-8 text-white">
        <li className="hover:text-gray-200 transition duration-200 ease-in-out">
          <Link to="#summary">
            Summary
          </Link>
        </li>
        <li className="hover:text-gray-200 transition duration-200 ease-in-out">
          <Link to="#details">
            Details
          </Link>
        </li>
        <li className="hover:text-gray-200 transition duration-200 ease-in-out">
          <Link to="#photos">
            Photos
          </Link>
        </li>
        <li className="hover:text-gray-200 transition duration-200 ease-in-out">
          <Link to="#map">
            Map
          </Link>
        </li>
        {!sold &&
        <li className="hover:text-gray-200 transition duration-200 ease-in-out">
          <Link to="#pricing">
            Pricing
          </Link>
        </li>}
      </ul>
    </div>
  )
}

export default PropertyNavbar
