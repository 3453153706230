import React from 'react'

import Section from "../../../components/section"

const PropertyMap = ({ mapright_url, title, interactive_map_url }) => {
  return (
    <Section backgroundColor="bg-white" id="map">
      <div>
        <h3 className="mb-6 text-2xl font-semibold text-gray-700">
          Property Map
        </h3>
        <div className="md:px-5 lg:px-0 overflow-hidden" id="image-container">
          <iframe className="w-full h-64 lg:h-128" src={mapright_url} title={`${title} - Property Map`} frameBorder="0"></iframe>
        </div>
       
        <div className="mt-10 overflow-hidden">
          <div className="max-w-max lg:max-w-7xl mx-auto">
            <div className="relative z-10 mb-8 md:mb-2">
              <div className="text-base max-w-prose lg:max-w-none">                
                <h3 className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-3xl">
                  Visit The Property
                </h3>
              </div>
            </div>
            <div className="relative">                          
              <div className="relative md:bg-white">
                <div className="mt-0 md:mt-6">
                  <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-2xl">
                    <p>
                      Get this interactive map sent to your smartphone so that you can use your phone’s GPS to find the property and walk the boundaries.
                    </p>                  
                    <ol>
                      <li>
                         Download the MapRight App onto your smartphone.
                      </li>
                      <li>
                        <a href={interactive_map_url} target="_blank" rel="noopener noreferrer" className="font-bold underline">Click this link</a> in your phone's browser.
                      </li>
                      <li>
                        Click the “Open in iOS” or “Open in Android” button.
                      </li>
                    </ol>                   
                  </div>                  
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>  
  )
}

export default PropertyMap
