import React from "react"
import { Link } from "gatsby"
import formatToUsd from "../../../utils/currency"
import BlockContent from "@sanity/block-content-to-react"

const PaymentDetails = ({ pricing, payment_url, section }) => {
  return (
    <div className="bg-green-900" id="pricing">
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto lg:max-w-none">
            <h4 className="text-base md:text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
              {section.subheading}
            </h4>
            <h2 className="mt-3 text-3xl leading-9 font-bold text-white sm:text-4xl lg:text-5xl sm:leading-10 lg:leading-none">
              {section.heading}
            </h2>
            <div className="prose mt-6 max-w-md md:max-w-3xl mx-auto text-base md:text-xl leading-8 text-gray-300">
              <BlockContent blocks={section._rawDescription} />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 pb-8 bg-gray-50 sm:mt-10 lg:mt-12">
        <div className="relative bg-green-900">
          <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              className={`max-w-md mx-auto space-y-4 lg:grid lg:gap-5 lg:space-y-0 ${
                pricing.monthly_payment
                  ? "lg:grid-cols-2 lg:max-w-4xl"
                  : "lg:grid-cols-1"
              }`}
            >
              {pricing.monthly_payment ? (
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-xs leading-5 font-semibold tracking-wide uppercase bg-green-100 text-green-600"
                        id="tier-standard"
                      >
                        {section.monthly.heading}
                      </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-5xl leading-none font-extrabold">
                      {formatToUsd(pricing.monthly_payment)}
                      <span className="ml-1 text-xl leading-8 font-medium text-gray-500">
                        /mo
                      </span>
                    </div>
                    {pricing.down_payment ? (
                      <p className="mt-4 text-lg leading-7 text-gray-500">
                        For{" "}
                        <span className="font-medium">
                          {pricing.number_of_months}
                        </span>{" "}
                        months with {formatToUsd(pricing.down_payment)} down*
                      </p>
                    ) : null}
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      {section.monthly.bullets.map((bullet, index) => (
                        <li
                          key={`bullet-${index}`}
                          className="flex items-start"
                        >
                          <div className="flex-shrink-0">
                            <svg
                              className="h-6 w-6 text-green-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 text-gray-700">
                            {bullet}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div>
                    <h3
                      className="inline-flex px-4 py-1 rounded-full text-xs leading-5 font-semibold tracking-wide uppercase bg-green-100 text-green-600"
                      id="tier-standard"
                    >
                      {section.cash.heading}
                    </h3>
                  </div>
                  <div className="mt-4 flex items-baseline text-5xl leading-none font-extrabold">
                    {formatToUsd(pricing.cash_price)}
                  </div>
                </div>
                <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                  <ul className="space-y-4">
                    {section.cash.bullets.map((bullet, index) => (
                      <li key={`bullet-${index}`} className="flex items-start">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-6 w-6 text-green-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 text-gray-700">
                          {bullet}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-5 md:mt-10 pb-6 md:pb-12">
              <div className="my-5 max-w-md md:max-w-sm mx-auto rounded-md shadow">
                <a
                  href={payment_url}
                  aria-describedby="tier-standard"
                  className="flex items-center justify-center px-5 py-3 uppercase border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  Buy now
                </a>
              </div>
              <div className="mt-4 mx-autof flex justify-center">
                <Link
                  to="/process"
                  className="cursor-pointer text-sm md:text-base text-center font-medium text-green-50 hover:text-green-100 underline sm:no-underline sm:hover:underline"
                >
                  Learn more about our buying process &rarr;
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="mx-auto max-w-xl lg:max-w-4xl text-center text-xs text-gray-500">
            <BlockContent blocks={section._rawFinePrint} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentDetails
