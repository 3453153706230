import React, { useState } from 'react'

import Section from "../../../components/section"

const PropertyDetails = ({ propertyDetails, files }) => {
  const [ searchQuery, setSearchQuery ] = useState('');
  const searchResults = Object.keys(propertyDetails)
    .filter(key => !([
      "cash_price", "down_payment", "monthly_payment", "number_of_months", "document_fee", "mapright_url",
    ].includes(key)))
    .filter(key => propertyDetails[key] !== null)
    .filter(key => key.replace(/_/g, " ").includes(searchQuery.toLowerCase()));
  
  return (
    <Section id="details">
      <h3 className="mb-6 text-2xl font-semibold text-gray-700">
        Property Details
      </h3>

      <div id="details-table" className="bg-white shadow sm:rounded-lg overflow-hidden">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">  
          <div className="mt-0 md:mt-3 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="z-10 h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>

            <input 
              id="search"
              placeholder="Type to search property details..."
              onChange={(evt) => setSearchQuery(evt.target.value)}
              className="relative form-input block w-full pl-10 sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div>
          <dl className="overflow-scroll max-h-72">
            {searchResults.length >= 1 && searchResults.map((detail, index) => {                 
              return propertyDetails[detail] !== null 
              ? (<div key={`property-detail-${index}`} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} px-4 py-3 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
                  <dt className="text-sm leading-5 font-semibold text-gray-700 capitalize">
                    {detail.replace(/_/g, " ")}
                  </dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-800 sm:mt-0 sm:col-span-2">
                    {propertyDetails[detail]}
                  </dd>
                </div>)
              : null
            })}
            {searchResults.length === 0 &&
              <div className="bg-white px-4 py-6 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                <p className="mt-1 text-base leading-5 text-gray-600 sm:mt-0 sm:col-span-3">
                  No results match those criteria.
                </p>
              </div>
            }
          </dl>
        </div>
      </div>

      {(files.length > 0 && files.every(f => !!f.asset)) &&
      <>
        <h3 className="mt-10 text-2xl font-semibold text-gray-700">
          Files
        </h3>

        <div className="mt-1 text-sm text-gray-900 sm:mt-6 sm:col-span-2">        
          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
            {files.map((file, index) => (
              <li key={`file-${index}`} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div className="w-0 flex-1 flex items-center">                
                  <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                  </svg>
                  <span className="ml-2 flex-1 w-0 truncate">
                    {file.asset.originalFilename}
                  </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a href={file.asset.url} download className="font-medium text-indigo-600 hover:text-indigo-500">
                    Download
                  </a>
                </div>
              </li>
            ))}          
          </ul>
        </div>
      </>}
    </Section>
  )
}

export default PropertyDetails
