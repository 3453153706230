import React from "react"
import Image from "gatsby-image"
import { graphql } from "gatsby"
import toUSD from "../../utils/currency"

import PropertySummary from "./components/property-summary"
import PropertyDetails from "./components/property-details"
import PaymentDetails from "./components/payment-details"
import PropertyNavbar from "./components/property-navbar"
import Testimonials from "../../components/testimonials"
import ImageGallery from "./components/image-gallery"
import Newsletter from "../../components/newsletter"
import PropertyMap from "./components/property-map"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PropertyPageTemplate = ({ data }) => {
  const {
    seo_description,
    title,
    sold,
    property_details,
    youtube_url,
    interactive_map_url,
    payment_url,
    images,
    files,
    _rawDescription,
  } = data.property

  return (
    <Layout>
      <SEO title={`${title}`} description={seo_description} />

      <div className="relative">
        <div className="bg-gray-50">
          <div className="container pt-10 sm:pt-24 pb-8 sm:pb-10 text-gray-800 text-center">
            <h1 className="max-w-2xl mx-auto px-4 sm:px-0 text-3xl sm:text-4xl font-regular">
              {title}
            </h1>
            <h4 className="text-xl">
              {property_details.county} County, {property_details.state}
            </h4>
          </div>
        </div>

        <PropertyNavbar sold={sold} />

        <div className="relative">
          <div className="overflow-hidden aspect-w-16 aspect-h-9 sm:aspect-h-7">
            {youtube_url ? (
              <iframe
                className="h-full"
                src={`https://www.youtube.com/embed/${
                  youtube_url.split("youtu.be/")[1]
                }`}
                title={`${title}`}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                allowFullScreen
              ></iframe>
            ) : (
              <div className="h-full w-full">
                <Image
                  className="h-full w-full object-cover"
                  fluid={images[0].asset.fluid}
                  alt={images[0].alt || "Property Banner"}
                />

                {property_details.monthly_payment ? (
                  <div className="absolute top-0 left-0 md:left-6 lg:left-20 px-2 md:px-4 py-2 lg:py-3 bg-secondary text-white rounded-b-md shadow-lg flex flex-col font-open-sans">
                    <span className="text-xs md:text-base text-gray-300">
                      Starting at:
                    </span>
                    <span className="text-sm md:text-4xl">
                      {toUSD(property_details.monthly_payment)}/mo
                    </span>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>

        <Newsletter />
        <PropertySummary _rawDescription={_rawDescription} />
        <PropertyDetails propertyDetails={property_details} files={files} />
        <ImageGallery images={images} />
        <PropertyMap
          title={title}
          interactive_map_url={interactive_map_url}
          mapright_url={property_details.mapright_url}
        />

        {!sold && (
          <PaymentDetails
            pricing={{
              down_payment: property_details.down_payment,
              cash_price: property_details.cash_price,
              monthly_payment: property_details.monthly_payment,
              number_of_months: property_details.number_of_months,
              document_fee: property_details.document_fee,
            }}
            payment_url={payment_url}
            section={data.page.pricing}
          />
        )}

        <Testimonials />
      </div>
    </Layout>
  )
}

export default PropertyPageTemplate

export const query = graphql`
  query PropertyPageQuery($id: String!) {
    property: sanityProperty(id: { eq: $id }) {
      id
      title
      sold
      acres
      property_details {
        address
        city
        state
        zip
        county
        parcel_id
        subdivision
        apn
        access
        zoning
        property_owners_association
        camping
        hunting
        terrain
        grade
        elevation
        power
        water
        sewer
        property_taxes
        conveyance
        gps_coordinates
        google_maps_link
        mapright_url
        cash_price
        down_payment
        document_fee
        monthly_payment
        number_of_months
      }
      youtube_url
      interactive_map_url
      payment_url
      images {
        alt
        caption
        asset {
          fluid(maxWidth: 1240) {
            ...GatsbySanityImageFluid
          }
        }
      }
      files {
        asset {
          url
          originalFilename
        }
      }
      _rawDescription
    }
    page: sanityPropertyPage {
      seo_description
      pricing {
        heading
        subheading
        _rawDescription
        monthly {
          heading
          bullets
        }
        cash {
          heading
          bullets
        }
        _rawFinePrint
      }
    }
  }
`
