import React from 'react'
import Section from "../../../components/section"
import BlockContent from "@sanity/block-content-to-react"

const PropertySummary = ({ _rawDescription }) => {
  return (
    <Section backgroundColor="bg-primary-lighter" id="summary">
      <div className="max-w-md mx-auto pb-2 border-b-2 border-gray-200">                    
        <h1 className="mt-2 mb-6 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl sm:leading-10">
          Property Summary
        </h1>
      </div>

      <div className="mt-10 mx-auto prose prose-md lg:prose-xl max-w-none max-w-prose">
        <BlockContent
          blocks={_rawDescription}
        />
      </div>
    </Section>
  )
}

export default PropertySummary
