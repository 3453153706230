import React, { useState, useEffect, useCallback } from 'react'
import Image from "gatsby-image"
import { Transition } from "@tailwindui/react"

import Section from "../../../components/section"

const ImageGallery = ({ images }) => {
  const [ lightboxIndex, setLightboxIndex ] = useState(null);  

  const displayNext = useCallback(
    (event={}) => {
      if (event.stopPropagation) event.stopPropagation();
      if (lightboxIndex === null) return;

      if (lightboxIndex === images.length - 1) {
        return setLightboxIndex(0);
      } else {
        return setLightboxIndex((lightboxIndex) => lightboxIndex + 1);
      }
    }, [lightboxIndex, images]
  );

  const displayPrevious = useCallback(
    (event={}) => {
      if (event.stopPropagation) event.stopPropagation();
      if (lightboxIndex === null) return;

      if (lightboxIndex === 0) {
        return setLightboxIndex(images.length - 1);
      } else {
        return setLightboxIndex((lightboxIndex) => lightboxIndex - 1);
      }
    }, [lightboxIndex, images]
  );

  useEffect(() => {
    const keyupHandler = (event) => {
      console.log('key up handler!')    
      if (lightboxIndex === null) return;
      
      switch(event.which) {      
        case 37: // left arrow key
          return displayPrevious();
        case 39: // right arrow key
          return displayNext();
        case 27: // escape key
          return setLightboxIndex(null);
        default:
          return;
      }
    };

    document.addEventListener('keyup', keyupHandler);
    return () => document.removeEventListener('keyup', keyupHandler);
  }, [ displayNext, displayPrevious, lightboxIndex ]);

  return (
    <Section backgroundColor="bg-gray-50" id="photos">
      <div className="">
        <h3 className="mb-6 text-2xl font-semibold text-gray-700">
          Property Photos
        </h3>
        <div className="" id="image-container">
          <ImageGrid 
            images={images} 
            numberToShow={3}
            handleClick={setLightboxIndex}
            className="sm:hidden"
          />
          <ImageGrid 
            images={images} 
            numberToShow={6}
            handleClick={setLightboxIndex}
            className="hidden sm:block"
          />
        </div>
      </div>

      <Transition
        appear={true}
        show={lightboxIndex !== null}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        onClick={() => setLightboxIndex(null)}
        className="fixed inset-0 w-screen h-full min-h-screen z-50 bg-black bg-opacity-75 transform"
      >        
        {lightboxIndex !== null && 
        <div className="absolute w-11/12 md:w-9/12 lg:h-11/12 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <Image className="w-full h-full" fluid={images[lightboxIndex].asset.fluid} alt={images[lightboxIndex].alt} />

          <div className="absolute h-12 sm:h-16 w-12 sm:w-16 top-1/2 left-0 sm:left-4 transform -translate-y-1/2 flex justify-center items-center group bg-transparent hover:bg-gray-900 hover:bg-opacity-50 rounded-full transition duration-200 ease-in-out">
            <svg onClick={displayPrevious} className="w-12 h-12 text-white cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
            </svg>
          </div>
          <div className="absolute h-12 sm:h-16 w-12 sm:w-16 top-1/2 right-0 sm:right-4 transform -translate-y-1/2 flex justify-center items-center group bg-transparent hover:bg-gray-900 hover:bg-opacity-50 rounded-full transition duration-200 ease-in-out">
            <svg onClick={displayNext} className="w-12 h-12 text-white cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
            </svg>  
          </div>                 
        </div>}        
        
        <svg className="absolute cursor-pointer w-8 h-8 top-5 right-6 text-white hover:text-gray-100 transition duration-150 ease-in-out" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </Transition> 
    </Section>   
  )
}

export default ImageGallery

const ImageGrid = ({ images, handleClick, numberToShow, className }) => {
  const [ expanded, setExpanded ] = useState(false);

  return (
    <div className={className}>
      <div className="md:px-5 lg:px-0 grid gap-3 md:grid-cols-2 lg:grid-cols-3">
        {images.slice(0, numberToShow).map((image, index) => {
          return (
            <div key={`image-${index}`} tabIndex="0" onKeyDown={(e) => e.key === 13 && handleClick(index)} onClick={() => handleClick(index)} className="rounded-sm shadow-lg overflow-hidden"> {/* z-0 relative */}
              <div className="h-48 cursor-pointer overflow-hidden">
                <Image className="w-full h-full object-cover object-bottom transition duration-300 ease-in-out transform hover:scale-105 hover:opacity-75" fluid={image.asset.fluid} alt={image.alt || 'Lightbox Image'} />
              </div>
            </div>
        )})}
      </div>

      <Transition
        show={expanded}
        className="mt-3 md:px-5 lg:px-0 grid gap-3 md:grid-cols-2 lg:grid-cols-3"
      >
        {images.slice(numberToShow).map((image, index) => {
          return (
            <div key={`image-${index}`} tabIndex="0" onClick={() => handleClick(index + numberToShow)} className="rounded-sm shadow-lg overflow-hidden"> {/* z-0 relative */}
              <div className="h-48 cursor-pointer overflow-hidden">
                <Image className="w-full h-full object-cover object-bottom transition duration-300 ease-in-out transform hover:scale-105 hover:opacity-75" fluid={image.asset.fluid} alt={image.alt || 'Lightbox Image'} />
              </div>
            </div>
        )})}
      </Transition>

      <button 
        type="button" 
        onClick={() => setExpanded(exp => !exp)}
        className="mt-3 w-full py-2 font-medium bg-gradient-to-b from-gray-50 to-gray-100 rounded-md text-green-600 hover:text-green-500 transition duration-200 ease-in-out"
      >
        { expanded ? "Show less images" : "Show more images" } 
      </button>  
    </div>
  )
}