import React from 'react'

const Section = ({ children, backgroundColor, className, ...otherProps }) => {
  return (
    <section className={`${backgroundColor}`} {...otherProps}>
      <div className={`max-w-screen-lg mx-auto py-12 sm:py-16 px-4 md:px-0 ${className}`}>
        { children }
      </div>
    </section>
  )
}

export default Section
